<template>
  <v-dialog
    v-model="dialogState"
    height="100%"
    persistent
    scrollable
    max-width="500px"
  >
    <v-form ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title
          class=" text-subtitle-1 grey lighten-2 text-uppercase d-flex justify-space-between"
        >
          <div v-if="formType === 'add'">New Organization</div>
          <div v-else>Edit Organization</div>
          <v-btn icon @click="closeForm">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="px-5 pt-2 mt-3">
          <v-row>
            <!--Color Picker (START)-->
            <v-col cols="2">
              <v-menu
                open-on-click
                :close-on-content-click="false"
                offset-y
                v-model="colorMenu"
                absolute
                :position-x="x"
                :position-y="y"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-avatar
                    :color="form.color"
                    size="42"
                    @click="show"
                    class="cursor-pointer"
                  ></v-avatar>
                </template>
                <v-card>
                  <v-color-picker v-model="selectedColor"></v-color-picker>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="colorMenu = false">Cancel</v-btn>
                    <v-btn color="primary" text @click="selectColor">Ok</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <!--Color Picker (END)-->

            <v-col cols="10" class="py-1 mt-2">
              <v-select
                :items="parentOrganizations"
                item-text="name"
                item-value="id"
                v-model="form.parent_id"
                dense
                outlined
                clearable
                label="Parent Organization"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="py-0 my-0">
            <v-col cols="12" class="py-0 my-0">
              <v-text-field
                dense
                v-model="form.name"
                label="Organization Name"
                outlined
                :error-messages="getOrganizationErrors.name"
                :rules="[getRules.required]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-0 my-0">
            <v-col cols="12" class="py-0 my-0">
              <v-text-field
                dense
                v-model="form.billing_email"
                type="email"
                :rules="[getRules.required, getRules.email]"
                label="Billing Email"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-0 my-0">
            <v-col cols="12" class="py-0 my-0">
              <v-text-field
                dense
                v-model="form.phone"
                :rules="[getRules.required, getRules.number]"
                label="Phone"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-0 my-0">
            <v-col cols="12" class="py-0 my-0">
              <v-text-field
                dense
                v-model="form.postal_code"
                :rules="[getRules.required]"
                label="Postal Code"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-0 my-0">
            <v-col cols="12" class="py-0 my-0">
              <v-text-field
                dense
                v-model="form.address"
                label="Address"
                min="0"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-0 my-0">
            <v-col cols="12" class="py-0 my-0">
              <v-text-field
                dense
                v-model="form.vat_id"
                :rules="[getRules.required]"
                label="VAT ID"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="py-0 my-0">
            <v-col cols="6" class="py-0 my-0">
              <v-text-field
                dense
                v-model="form.city"
                label="City"
                min="0"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="6" class="py-0 my-0">
              <v-autocomplete
                outlined
                dense
                :items="countries"
                label="Country"
                v-model="form.country"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="px-5 py-3 elevation-4">
          <v-btn :loading="loading" block color="primary" @click="validate">
            <span v-if="formType === 'add'">
              <v-icon class="mr-2">mdi-plus</v-icon>
              Add Organization
            </span>
            <span v-else>
              <v-icon class="mr-2">mdi-pencil</v-icon>
              Update Organization
            </span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "OrganizationForm",
  props: ["formType", "dialogState"],
  data: () => ({
    validForm: true,
    form: new Form(),
    loading: false,
    selectedColor: null,
    colorMenu: false,
    countries: [
      "Afghanistan",
      "Albania",
      "Algeria",
      "Andorra",
      "Angola",
      "Antigua & Deps",
      "Argentina",
      "Armenia",
      "Australia",
      "Austria",
      "Azerbaijan",
      "Bahamas",
      "Bahrain",
      "Bangladesh",
      "Barbados",
      "Belarus",
      "Belgium",
      "Belize",
      "Benin",
      "Bhutan",
      "Bolivia",
      "Bosnia Herzegovina",
      "Botswana",
      "Brazil",
      "Brunei",
      "Bulgaria",
      "Burkina",
      "Burundi",
      "Cambodia",
      "Cameroon",
      "Canada",
      "Cape Verde",
      "Central African Rep",
      "Chad",
      "Chile",
      "China",
      "Comoros",
      "Congo",
      "Congo {Democratic Rep}",
      "Costa Rica",
      "Croatia",
      "Cuba",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Djibouti",
      "Dominica",
      "Dominican Republic",
      "East Timor",
      "Ecuador",
      "Egypt",
      "El Salvador",
      "Equatorial Guinea",
      "Eritrea",
      "Estonia",
      "Ethiopia",
      "Fiji",
      "Finland",
      "France",
      "Gabon",
      "Gambia",
      "Georgia",
      "Germany",
      "Ghana",
      "Greece",
      "Grenada",
      "Guatemala",
      "Guinea",
      "Guinea-Bissau",
      "Guyana",
      "Haiti",
      "Honduras",
      "Hungary",
      "Iceland",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Ireland {Republic}",
      "Israel",
      "Italy",
      "Ivory Coast",
      "Jamaica",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kenya",
      "Kiribati",
      "Korea North",
      "Korea South",
      "Kosovo",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Latvia",
      "Lebanon",
      "Lesotho",
      "Liberia",
      "Libya",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macedonia",
      "Madagascar",
      "Malawi",
      "Malaysia",
      "Maldives",
      "Mali",
      "Malta",
      "Marshall Islands",
      "Mauritania",
      "Mauritius",
      "Mexico",
      "Micronesia",
      "Moldova",
      "Monaco",
      "Mongolia",
      "Montenegro",
      "Morocco",
      "Mozambique",
      "Myanmar",
      "{Burma}",
      "Namibia",
      "Nauru",
      "Nepal",
      "Netherlands",
      "New Zealand",
      "Nicaragua",
      "Niger",
      "Nigeria",
      "Norway",
      "Oman",
      "Pakistan",
      "Palau",
      "Panama",
      "Papua New Guinea",
      "Paraguay",
      "Peru",
      "Philippines",
      "Poland",
      "Portugal",
      "Qatar",
      "Romania",
      "Russian Federation",
      "Rwanda",
      "St Kitts & Nevis",
      "St Lucia",
      "Saint Vincent & the Grenadines",
      "Samoa",
      "San Marino",
      "Sao Tome & Principe",
      "Saudi Arabia",
      "Senegal",
      "Serbia",
      "Seychelles",
      "Sierra Leone",
      "Singapore",
      "Slovakia",
      "Slovenia",
      "Solomon Islands",
      "Somalia",
      "South Africa",
      "South Sudan",
      "Spain",
      "Sri Lanka",
      "Sudan",
      "Suriname",
      "Swaziland",
      "Sweden",
      "Switzerland",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Tanzania",
      "Thailand",
      "Togo",
      "Tonga",
      "Trinidad & Tobago",
      "Tunisia",
      "Turkey",
      "Turkmenistan",
      "Tuvalu",
      "Uganda",
      "Ukraine",
      "United Arab Emirates",
      "United Kingdom",
      "United States",
      "Uruguay",
      "Uzbekistan",
      "Vanuatu",
      "Vatican City",
      "Venezuela",
      "Vietnam",
      "Yemen",
      "Zambia",
      "Zimbabwe",
    ],
    x: 0,
    y: 0,
  }),
  mounted() {
    this.initForm();
  },
  computed: {
    parentOrganizations() {
      let array = this.getOrganizations;
      let value = this.getAccount.id;
      let o = [];
      array.some(function iter(a) {
        if (a["account_id"] === value) {
          o.push(a);
        }
        return Array.isArray(a.children) && a.children.some(iter);
      });
      if (this.formType === "edit") {
        o = o.filter((obj) => {
          return obj.id !== this.getOrganization.id;
        });
      }
      return _.orderBy(o, ["created_at"], ["asc"]);
    },
    ...mapGetters([
      "getRules",
      "getAuthUser",
      "getOrganization",
      "getOrganizations",
      "getAccount",
      "getOrganizationAddStatus",
      "getOrganizationUpdateStatus",
      "getOrganizationErrors",
    ]),
  },
  watch: {
    getOrganization() {
      this.initForm();
    },
    formType() {
      this.initForm();
    },
  },
  methods: {
    initForm() {
      if (this.$refs.form) this.$refs.form.resetValidation();
      if (this.formType === "edit") {
        this.form = new Form(this.getOrganization);
        this.selectedColor = {
          hex: this.form.color,
        };
      } else {
        this.form = new Form({
          color: "primary",
        });
      }
    },
    selectColor() {
      this.form.color = this.selectedColor.hex;
      this.colorMenu = false;
    },
    show(e) {
      e.preventDefault();
      this.colorMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.colorMenu = true;
      });
    },
    closeForm() {
      if (this.formType === "add") {
        this.resetOrganizationErrors();
        this.$refs.form.resetValidation();
      }
      this.$emit("close-form", true);
    },
    showMessage(type = "add") {
      this.$emit("successMessage", type);
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.submit();
      }
    },
    async submit() {
      if (this.formType === "add") {
        this.form.account_id = this.getAccount.id;
      }
      let organization = Object.assign({}, this.form);
      this.loading = true;
      if (this.formType === "add") {
        organization.organization_type = "seller";
        await this.addOrganization(organization);
      } else {
        this.setOrganization(organization);
        await this.updateOrganization(organization);
      }
      this.loading = false;
      if (
        this.getOrganizationAddStatus === 2 ||
        this.getOrganizationUpdateStatus === 2
      ) {
        this.loadAccounts({ mlforceload: true, mlsilent: true });
        this.loadOrganizations({ mlforceload: true, mlsilent: true });
        this.closeForm();
        this.showMessage(this.formType);
      }
    },
    ...mapMutations(["setOrganization", "resetOrganizationErrors"]),
    ...mapActions([
      "updateOrganization",
      "addOrganization",
      "loadOrganizations",
      "loadAccounts",
    ]),
  },
};
</script>
